import { createContext, useState } from "react";

export const LabelKbnContext = createContext({})

export const LabelKbnProvider = props =>{
    const {children} =props;
    const [ labelKbn, setLabelKbn ] = useState("A");

      return(
        <LabelKbnContext.Provider value={{ labelKbn, setLabelKbn}}>
           { children }
        </LabelKbnContext.Provider>
    )
}