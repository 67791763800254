import { createContext, useState } from "react";
import moment from 'moment';

export const DateContext = createContext({})

export const DateProvider = props =>{
    const {children} =props;
    const [ date, setDate ] = useState(moment(new Date()).format("yyyy-MM-DD"));

      return(
        <DateContext.Provider value={{ date, setDate }}>
           { children }
        </DateContext.Provider>
    )
}