import { createContext, useState } from "react";

export const PrintFlagContext = createContext({})

export const PrintFlagProvider = props =>{
    const {children} =props;
    const [ printFlag, setPrintFlag ] = useState("0");

      return(
        <PrintFlagContext.Provider value={{printFlag, setPrintFlag }}>
           { children }
        </PrintFlagContext.Provider>
    )
}