import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import  {useState} from 'react'

export default function PrintDialog(props) {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false) //ダイアログ閉じる
    const handleShow = () => setShow(true) //ダイアログ開く
  
    const restartPrint = () =>{
      fetch('/api/WebLabelPrintInfo/putLabelInfo?date='+props.select.print_date+'&flag=2&number='+props.select.display_order_number+"&kbn="+props.select.kbn);
      handleClose();
    }
    return(
    <>
     <Button className='rePrintBtn' onClick={handleShow} >
        再印刷
      </Button>

        <Modal show={show} onHide={handleClose}  size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title>再印刷</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {props.select.list_show}の再印刷をおこないますか？
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={restartPrint}>
              はい
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              閉じる
          </Button>
          </Modal.Footer>
        </Modal>
    </>
    )
}
