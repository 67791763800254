import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { LabelKbnProvider } from './provider/labelKbnProvider';
import { PrintFlagProvider } from './provider/printFlagProvider';
import { DateProvider } from './provider/dateProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LabelKbnProvider>
      <DateProvider>
        <PrintFlagProvider>
          <App />
        </PrintFlagProvider>
      </DateProvider>
    </LabelKbnProvider>
  </React.StrictMode>
);


