import { createContext, useEffect, useState ,useContext } from "react";
import Loading from "../compornent/loading";
import { DateContext } from '../provider/dateProvider';
import { PrintFlagContext } from '../provider/printFlagProvider';
import { LabelKbnContext } from '../provider/labelKbnProvider';

export const PrintListContext = createContext({})

export const PrintListProvider =props =>{
    const [isLoading, setIsLoading] = useState(false);
    const {children} =props;
    const [time, setTime] = useState(null);
    const [printList,setPrintList] = useState([]);
    const { date, setDate } = useContext(DateContext);  
    const {printFlag, setPrintFlag} = useContext(PrintFlagContext);
    const { labelKbn, setLabelKbn} = useContext(LabelKbnContext);
  
    useEffect(()=>{
        setInterval(() => { 
            setTime(new Date());
        },10000);
    },[])

    useEffect(()=>{
        fetch('/api/WebLabelPrintInfo/getPrintList?date='+date+'&printFlag='+printFlag+'&kbn='+labelKbn, {
            method: "GET",
            mode: "cors"
        })
        .then(res => res.json())
        .then(res => {setPrintList(res); setIsLoading(false);})
        .catch(err => {setIsLoading(false); console.log(err)}) 
    },[date,printFlag,labelKbn,time])
    return(
        <PrintListContext.Provider value={{printList,setPrintList}}>
            { isLoading ? <Loading /> : children }
        </PrintListContext.Provider>
    )
}