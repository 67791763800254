import { LabelKbnContext } from './provider/labelKbnProvider';
import { useContext } from 'react';

function Header() {
    const { labelKbn, setLabelKbn} = useContext(LabelKbnContext);

    /** ラジオボタン */
    const radioButtons = [
    {
        label: "お弁当ラベル",
        value: "A"
    },
    {
      label: "パックラベル",
      value: "B"
    }
    ]
    
    const labelKbnset = (e) =>{
      setLabelKbn(e.target.value);
    }

  return (
    <div className='row header mb-3'>
    <div className='col-lg-4 text-white text-lite'>
      ラベル印刷
    </div>
    <div className='col-lg-8 p-0'>
      <div className='row p-0 m-0'>
        {radioButtons.map(radio => {
            return (
                <div className="col-6 text-center p-0" key={radio.value}>
                    {/* checked属性に式を定義する */}
                    <input className="radiobutton form-check-input" type="radio" name="menu" value={radio.value} id={radio.value} defaultChecked={radio.value === labelKbn} onChange={labelKbnset} />
                    <label className="form-check-label" htmlFor={radio.value}>
                        <span>{radio.label}</span>
                    </label>
                </div>
            )
        })}
      </div>
    </div>
  </div>
  );

}

export default Header;
