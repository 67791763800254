import Header from './header';
import SearchList from './compornent/searchListCompornent';
import { PrintListProvider } from './provider/printListProvider';
import LabelList from './compornent/labelListCompornent';

export default function App() {  
  return (
    <div className='container-fluid'>
        <Header />

        <SearchList />
        <PrintListProvider>
          <LabelList />
        </PrintListProvider>
        
    </div>
  );
}